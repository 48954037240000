import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/games-icon/home-icon.png";
import AviatorIcon from "../assets/images/games-icon/aviator.png";
import CasinoIcon from "../assets/images/games-icon/casino-icon.png";
import SportsIcon from "../assets/images/games-icon/sports-icon.png";
import InplayIcon from "../assets/images/games-icon/inplay-icon.png";
import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import IceHockeyIcon from "../assets/images/games-icon/icehockey-icon.png";
import BadmintonIcon from "../assets/images/games-icon/badminton-icon.png";
import BasketballIcon from "../assets/images/games-icon/basketball-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TableTennisIcon from "../assets/images/games-icon/table-tennis-icon.png";
import MotorSportIcon from "../assets/images/games-icon/motor-sport-icon.png";
import horseracingIcon from "../assets/images/games-icon/horseracing-icon.png";
import GreyhoundIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";

import WalletIcon from "../assets/images/WalletIcon.png";
import UserIcon from "../assets/images/user-icon.svg";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import LogoutIconImg from "../assets/images/logout.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";
import ThemeToggle, {
  BUTTON_PARENT_TYPE,
} from "../views/components/ThemeToggle";
import { setSelectedGateway } from "../redux/user/actions";
const MobLeftbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);

  const { user, balance, wallet } = useSelector((state) => state.auth);
  const gateway = {
    id: "XfLFol4F",
    slug: null,
    name: "FAST UPI HUB",
    logo: null,
    type: "auto",
    order: 1,
    code: "gatewayhub",
    value: null,
    displayValue: null,
    qrCode: null,
    url: "/deposit/pay/gatewayhub",
    fields: [
      {
        label: "Amount",
        type: "number",
        accessor: "amount",
        required: true,
        display: true,
        value: null,
      },
    ],
  };
  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec">
        {isAuth && (
          <>
            <div className="d-flex profilemobsection">
              <div className="profile-img">
                <img src={UserIcon} alt="User Icon" />
              </div>
              <div className="user-info">
                <Link className="user_name" to="/profile">
                  {user?.mstruserid}
                </Link>
                <p>{user?.mobileno}</p>
              </div>
            </div>
            <div className="container upperbalnace">
              <div className="d-flex align-items-center justify-content-between  mb-3">
                <div className="balance">
                  <img src={WalletIcon} alt="wallet" />
                  Balance
                </div>
                <div className="balance-amount"> INR {wallet?.balance}</div>
              </div>

              <div className="row">
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    variant="primary"
                    onClick={() => {
                      dispatch(setSelectedGateway(gateway));
                      navigate(`/deposit/${gateway.name}`);
                    }}
                    className="btn_primary w-100"
                  >
                    Deposit
                  </Button>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/bonus");
                  }}
                >
                  <Button variant="" className="btn_withdraw mt-2 w-100">
                    Bonus
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/mybets");
                  }}
                >
                  <Button variant="" className="btn_withdraw  mt-2 w-100">
                    My Bets
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        <ul>
          <li className="games_link">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/spribe/aviator");
                  }}
                >
                  <img src={AviatorIcon} alt="Inplay Icon" />
                  <span>Aviator</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/worldcasino");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>World Casino</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/betby", {
                      state: { selectedTab: "1" },
                    });
                  }}
                >
                  <img src={SoccerIcon} alt="sportsbook" />
                  <span>Sportsbook</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/atlas-lobby", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={SportsIcon} alt="sportsbook2" />
                  <span>Sportsbook2</span>
                </div>
              </li>
              {isAuth && (
                <li>
                  <div
                    onClick={() => {
                      navigate("/referral");
                    }}
                  >
                    <img src={refferal} alt="Casino Icon" className="ref" />
                    <span>Referral</span>
                  </div>
                </li>
              )}
            </ul>
          </li>

          <li className="games_link">
            <h6>Sports</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Inplay", {
                      state: { selectedTab: "1" },
                    });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay" />
                  <span>In-play</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Cricket", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={CricketIcon} alt="Cricket" />
                  <span>Cricket</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Tennis", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={TennisIcon} alt="Tennis" />
                  <span>Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Inplay", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={IceHockeyIcon} alt="Ice Hockey" />
                  <span>Ice Hockey</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Badminton", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={BadmintonIcon} alt="Badminton" />
                  <span>Badminton</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Inplay", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={BasketballIcon} alt="Basketball" />
                  <span>Basketball</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Soccer", {
                      state: { selectedTab: "3" },
                    });
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer" />
                  <span>Soccer</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Table Tennis", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={TableTennisIcon} alt="Table Tennis" />
                  <span>Table Tennis</span>
                  <div className="sport-event-count badge">
                    {sportsData?.[2]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Motor Sport", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={MotorSportIcon} alt="Motor Sport" />
                  <span>Motor Sport</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Horse Racing", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={horseracingIcon} alt="Horse Racing" />
                  <span>Horse Racing</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Greyhound Racing", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={GreyhoundIcon} alt="Greyhound Racing" />
                  <span>Greyhound Racing</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Politics", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={PoliticsIcon} alt="Politics" />
                  <span>Politics</span>
                </div>
              </li>
            </ul>
          </li>

          {isAuth && (
            <li className="games_link">
              <h6>My Account</h6>
              <ul className="account-menu">
                <li>
                  <div>
                    <a href="/mybets">
                      <img src={BetIcon} alt="my bet" />
                      <span>My Bets</span>
                    </a>
                  </div>
                </li>
                <li>
                  <div>
                    <a href="/bonus" className="img_size">
                      <img src={BonusImg} />
                      <span className="bonus-space">Bonus</span>
                    </a>
                  </div>
                </li>
                {/* <li>
                  <div>
                    <a href="/referral" className="img_size">
                      <img src={refferal} />

                      <span className="bonus-space">Referral</span>
                    </a>
                  </div>
                </li> */}
                <li>
                  <div>
                    <a href="/cashier">
                      <img src={AcStatement} alt="account statement" />
                      <span>Account Statement</span>
                    </a>
                  </div>
                </li>
                <li onClick={() => dispatch(logoutUser())}>
                  <div>
                    <a href="/">
                      {/* <LogoutIcon /> */}
                      <img src={LogoutIconImg} />
                      <span>Sign Out</span>
                    </a>
                  </div>
                </li>
                <li>
                  <ThemeToggle buttonParentType={BUTTON_PARENT_TYPE.SIDEBAR} />
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MobLeftbar;
