import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import BtmFootInplay from "../assets/images/icons/home/btmFootInplay.png";
import BtmFootSports from "../assets/images/icons/home/btmFootSports.png";
import BtmFootHome from "../assets/images/icons/home/btmFootHome.png";
import BtmFootCasino from "../assets/images/icons/home/btmFootCasino.png";
import BtmFootProfile from "../assets/images/icons/home/btmFootProfile.svg";
import BtmFootApk from "../assets/images/icons/home/social.png";
import { useSelector } from "react-redux";

export default function BottomNav() {
  const location = useLocation();
  const { appDetails } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState("");
  const { isAuth } = useSelector((state) => state.auth);
  const isApk = localStorage.getItem("isApk") === "true";

  const footerPages = [
    "sportsbook",
    "home",
    "live-dealer",
    "profile",
    "sportsbook",
    "support-customer",
    "casino/evolution",
    "casino/ezugi",
    "public-inplay",
    "public-sports",
    "/",
    "sports",
  ];
  useEffect(() => {
    const matchedValue = footerPages.find((f) => {
      if (f === "public-sports") {
        return location?.pathname?.split("/")?.[1] === "public-sports";
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-lg-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li className="inplay">
            <a
              className={
                activePage === "sportsbook" || activePage === "public-inplay"
                  ? "active"
                  : ""
              }
              href={isAuth ? "/sportsbook/Inplay" : "/public-inplay"}
            >
              <div className="imgBox"></div>
              <img src={BtmFootInplay} alt="inplay" />
              <span>In play</span>
            </a>
          </li>
          <li className="sports">
            <a
              className={
                activePage === "sportsbook" || activePage === "public-sports"
                  ? "active"
                  : ""
              }
              href={isAuth ? "/sportsbook/Cricket" : "/public-sports"}
            >
              <div className="imgBox"></div>
              <img src={BtmFootSports} alt="exchange" />
              <span>Exchange</span>
            </a>
          </li>
          <li className="home">
            <a
              className={
                activePage === "sports" || activePage === "/" ? "active" : ""
              }
              href="/sports"
            >
              <div className="imgBox"></div>
              <img src={BtmFootHome} alt="home" />
              <span>Home</span>
            </a>
          </li>
          <li className="casino">
            <a
              className={activePage === "live-dealer" ? "active" : ""}
              href={isAuth ? "/live-dealer" : "/live-dealer-public"}
            >
              <div className="imgBox"></div>
              <img src={BtmFootCasino} alt="casino" />
              <span>Casino</span>
            </a>
          </li>
          {isAuth ? (
            <li className="profile">
              <a
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <div className="imgBox"></div>
                <img src={BtmFootProfile} alt="support" />
                <span>Support</span>
              </a>
            </li>
          ) : (
            <>
              {!isApk ? (
                <li className="apk-dwnld">
                  <a
                    href="https://dl.dropbox.com/scl/fi/udz5gxnn00k43izjlieh8/Goa777.apk?rlkey=q8ru5iuj47m3bdc20qk1b08ol&st=s3mnf7wu&dl=0"
                    download="https://dl.dropbox.com/scl/fi/udz5gxnn00k43izjlieh8/Goa777.apk?rlkey=q8ru5iuj47m3bdc20qk1b08ol&st=s3mnf7wu&dl=0"
                  >
                    <div className="imgBox"></div>
                    <img
                      src={BtmFootApk}
                      alt="apk"
                      style={{ height: "25px", maxHeight: "30px", top: "4px" }}
                    />
                    <span>Download APP</span>
                  </a>
                </li>
              ) : null}
            </>
          )}
        </ul>
      </div>
    </>
  );
}
